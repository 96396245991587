<template>
  <div class="master-toko">
    <div class="section">
      <header-page>
        <span class="size14 fw-bold-700" style="color: #0b5fff; margin-right: 5px">Master</span>
        <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
        <span class="text-dark fw-bold-400 size14">Billing</span>
      </header-page>

      <TableBilling :get-list-subscription="getListSubscription" :result="result" @filter="getFilter" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { BContainer, BButtonGroup, BButton } from "bootstrap-vue";
import TableBilling from "@/components/Billing/TableBilling.vue";
import TableTambahan from "@/components/Billing/TableTambahan.vue";

import HeaderPage from "@/components/HeaderPage.vue";

export default {
  title() {
    return "Master Billing";
  },
  components: {
    BContainer,
    BButtonGroup,
    BButton,
    HeaderPage,
    TableBilling,
    TableTambahan,
  },
  data() {
    return {
      // comp: "TableBilling",
      // tabFilter: "Toko Utama",
      listFilter: ["Toko Utama", "Tambahan"],
      filter: "Toko Utama",
      comp: "TableBilling",
    };
  },
  mounted() {
    if (this.$route.query.filter == "tambahan") {
      this.filter = "Tambahan";
      this.comp = "TableTambahan";
    }
  },
  //   watch: {
  //     filter: {
  //       handler: _.debounce(function () {
  //         this.getListSubscription();
  //       }, 300),
  //       deep: true,
  //     },
  //   },

  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.master-toko {
  button.bg-white {
    &:focus {
      background-color: transparent !important;
    }
  }
}
</style>
